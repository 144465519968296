import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, Grid, CircularProgress, MenuItem, Select, FormControl, 
  InputLabel, Card, CardContent, Container, IconButton, Typography, 
  Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import GridComponent from './UpdatableGrid';
import { toast, ToastContainer } from 'react-toastify';
import { AttachFile, Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import PopupFormComponent from './EvaluationForm';
import { API_URL } from './constants';
import TermsDialog from './TermsandCondition';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import FeedbackPopup from './FeedbackPopup';
import PromptAssistantModal from './PromptAssistant';
import {callPythonAPI} from './APICalls';
import { ChevronDownIcon, PaperclipIcon, XIcon, ArrowLeftIcon, ArrowRightIcon, ArrowBigUp } from 'lucide-react';

const Home = ({user}) => {
  const [prompt, setPrompt] = useState('');
  const [selectedOption, setSelectedOption] = useState('custom');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [showGrid, setShowGrid] = useState(false);
  const [number, setNumber] = useState(100);
  const [loadMoreNumber, setLoadMoreNumber] = useState(0);
  const [temperature, setTemperature] = useState(1);
  const [showSpinner, setshowSpinner] = useState(false);
  const [columnNames, setColumnNames] = useState('');
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);

  const [isDisabled, setIsDisabled] = useState(true);
  const [receivedCount, setReceivedCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  // To animate to the grid.
  const [animate, setAnimate] = useState(false);
  const [showColNames, setShowColNames] = useState(true);
  // const gridRef = useRef(null);

  const [showResults, setShowResults] = useState(false);
  // const [isLoadMoreClicked, setIsLoadMoreClicked] = useState(false);
  const containerRef = useRef(null);
  const [loadedNumUntilNow, setloadedNumUntilNow] = useState(number);
  const [totalRequiredNumber, setTotalRequiredNumber] = useState(0);
  
  const [isOpenSource, setIsOpenSource] = useState(true);
  var openSourceModels = ["Qwen2.5-72B-Instruct", "Meta-Llama-3.1-405B-Instruct", 
    "Gemini-2.0-Flash-exp"];
  // "Meta-Llama-3.1-8B-Instruct", "Meta-Llama-3.1-70B-Instruct", "Meta-Llama-3.3-70B-Instruct"
  const [selectedModel, setSelectedModel] = useState("Gemini-2.0-Flash-exp")
  const closedSourceModels = ["Gemini-1.5-Flash", "Gemini-2.0-Flash-exp"];
  const models = isOpenSource ? openSourceModels : closedSourceModels;
  // const [models, setModels] = useState(openSourceModels);
  const [isModelDisabled, setIsModelDisabled] = useState(false);
  const [runTour, setRunTour] = useState(true);

  // Prompt Assistant
  const [promptAssistant, setPromptAssistant] = useState('');
  const [ispromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);

  const steps = [
    {
      target: '#promptLabel',
      content: (
      <div className="text-left">
      <p>
        In the prompt field, describe the data you want in detail to get the most relevant results. Below are examples of a simple and a detailed prompt:
      </p>
      <ul className="list-disc list-inside">
        <li>
          <strong>Simple Prompt Example: </strong>
          <em>"Generate movie reviews for sentiment analysis."</em>
        </li>
        <li>
          <strong>Detailed Prompt Example: </strong>
          <em>
            "Generate movie reviews about the latest action and drama films, labeled as 
            'positive,' 'negative,' or 'neutral.' Include short and informal language, common in social media 
            posts, with a maximum length of 240 characters for each review."
          </em>
        </li>
      </ul>
    </div>
    ),
    disableBeacon: true,
    },
    // {
    //   target: '#task',
    //   content: 
    //   (
    //     <div className="text-left">
    //       <p>
    //         You can select the type of task you would like to generate data for. Here are the tasks we currently offer:
    //       </p>
    //       <ul className="list-disc list-inside">
    //         <li><strong>Text Classification: </strong>Classify text data into predefined categories. 
    //           <em> (e.g., "Classify customer reviews as positive, neutral, or negative.")</em>
    //         </li>
    //         <li><strong>Question Answering: </strong>Generate questions and corresponding answers for various topics. 
    //           <em> (e.g., "Provide questions and answers based on Wikipedia articles about world history.")</em>
    //         </li>
    //         <li><strong>Instruction Tuning: </strong>Create datasets that train models to follow instructions efficiently.
    //           <em> (e.g., "Generate Instructions to correct grammatical errors in input sentences.")</em>
    //         </li>
    //         <li><strong>Custom: </strong>When your usecase does not match any other usecase, please select custom.
    //           <em> (e.g., "Data for Recommendation systems, Named Entity Recognition etc.,")</em>
    //         </li>
    //       </ul>
    //     </div>
    //   ),
    // },
    {
      target: '#language',
      content: (
        <div className="text-left">
          <p>
            Pick the language in which you want the data to be generated. For now, English is the default, but the Indian languages Hindi and Telugu are also available. 
            If you want a specific language that is not available, please contact us @ <a href="mailto:team@datacreatorai.com" className="text-blue-500">team@datacreatorai.com</a>.
          </p>
        </div>
      )
    },
    {
      target: '#modelDropdown',
      content: (
        <div className="text-left">
          <p>
            Select a model that will be used to generate your dataset. Gemini-2.0 will be used by default.
          </p>
        </div>
      )
    },
    {
      target: '#number',
      content: (
      <div className = "text-left">
        <p>
        Specify the minimum number of data samples you would like to generate. The default number is 100.
        Click on Order for Later if you need customized datasets with more data points.
        {/* Subscribe to our Pro version for faster generation of datasets upto 1000 samples at once.
        <a href="https://datacreatorai.com/pricing" className="text-blue-500"> Switch to Pro</a> */}
        </p>
      </div>
      )
    },
    {
      target: '#columnNames',
      content: 
      (<div className="text-left">
      <p>
        Here, you can specify 5 custom column names for your data. Use comma-separated names like 
        <em> Column1, Column2, Column3.</em> Click on Order For Later to generate datasets with unlimited number of columns.
      </p>
      <br />
      {/* <ul className="list-disc list-inside">
        <li><strong>Text Classification: </strong><em>Text, Label</em></li>
        <li><strong>Question Answering: </strong><em>Question, Answer</em></li>
        <li><strong>Instruction Tuning: </strong><em>Instruction, Input, Output</em></li>
      </ul> */}
      </div>)
    },
    {
      target: '#fileupload',
      content: (
        <div className='text-left'>
          <p>
          You can augment your existing datasets by uploading your data as CSV Files. 
          The current file size limit is 50 KB. If you need larger file uploads, please contact us @ <a href="mailto:team@datacreatorai.com" className="text-blue-500">team@datacreatorai.com</a>.
          </p>
        </div>
      )
    },
    // {
    //   target: '#go',
    //   content: 'Click here to start generating your data!'
    // },
    {
      target: '#later',
      content: 'Enter your requirements and have your dataset sent to your email address.'
    }
  ];

  const handleUpdate = async (newValue) => {
    console.log("New Value:" + newValue);
    setPromptAssistant(newValue);
  }

  const handleOrderForLater = async () => {
    if(!validateInputs(true)){
      return;
    }

    const params = {
      userInput : prompt,
      colNames: columnNames
    };
    
    setOrderLoading(true);
    if(uploadedFile) {
      setPromptAssistant("");
      setOrderLoading(false);
      await handleLaterClick();
      return;
    }

    const response = await callPythonAPI(params);
    
    if (response.success) {
      console.log(response.result);
      setPromptAssistant(response.result);
      setIsPromptModalOpen(true);
      setOrderLoading(false);
    } else {
      // setError(response.error);
      setPromptAssistant(prompt);
      setIsPromptModalOpen(true);
      setOrderLoading(false);
    }
    // alert("Prompt Clicked!")
  };

  const handlePromptAssistant = async(updatedPrompt) => {
    setIsPromptModalOpen(false);
    await handleLaterClick(updatedPrompt);
  };

  // const handleSubmitOrderLater = async () => {

  // }

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setRunTour(true);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setRunTour(false);
    }
  };
  
  // const handleJoyrideCallback = (data) => {
  //   const { status } = data;
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     setRunTour(false);
  //   }
  // };

  const handleLaterClick = async(updatedPrompt) => {
    if (validateInputs(true)) {
      toast.success("Your data has been successfully ordered. You will shortly receive an email with further steps.")
      const formData = new FormData();
      formData.append('prompt', prompt);
      console.log("Updated Prompt while posting order:" + promptAssistant)
      formData.append('updatedPrompt', updatedPrompt);
      formData.append('usecase', selectedOption);
      formData.append('number', number);
      formData.append('language', selectedLanguage);
      formData.append('user_id', user.uid);
      formData.append('include_search_results', isChecked);
      formData.append('temperature', temperature);
      formData.append('model', selectedModel);
      formData.append('is_open_source', isOpenSource);
      formData.append('column_names', columnNames);

      if (uploadedFile) {
        formData.append('file', uploadedFile);
      }

      try {
        await fetch(`${API_URL}/api/order-for-later`, {
          method: 'POST',
          // headers: { 'Content-Type': 'application/json' },
          body: formData
        });
      } catch (error) {
        console.error('Error submitting feedback:', error);
        // alert('Error submitting feedback');
      }
      finally {
        setPromptAssistant("");
      }
    }
  }

  // const startTour = () => {
  //   setRunTour(true);
  // };

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 50 * 1024) {
        toast.error('File size exceeds 50 KB limit. Please contact us for more details.');
        return;
      }
      setFileName(file.name);
      setUploadedFile(file);
      setLoading(false);
      // setModels(["Gemini-1.5-Flash"]);
      // setSelectedModel("Gemini-1.5-Flash");
      // setIsOpenSource(false);
      // setIsModelDisabled(true);
      toast.info("File Uploaded Successfully.")
    }
    try {
      const headers = await readCSVHeader(file);
      setColumnNames(headers.join(', '));
    } catch (error) {
      console.error('Error reading CSV header:', error);
      toast.error('Failed to read column names from the CSV file.');
    }
   };

   const handleRemoveFile = () => {
    setFileName('');
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateInputs = (isOrderLater) => {
    if (prompt.trim().length === 0 || prompt.split(' ').length < 3) {
      toast.error('Please enter a description with at least 3 words.');
      return false;
    }

    if (selectedOption.trim().length === 0) {
      toast.error('Please select a task.');
      return false;
    }

    if(!isOrderLater) {
      if (!isNaN(number) && Number(number) > 500) {
        toast.error('We support only 500 data points at a time for free trials at once. Please subscribe to the Pro version to generate upto 1000 data points at once.');
        return false;
      }
    }

    if (isNaN(number) || Number(number) < 0) {
      toast.error('Invalid Number of Records! Please enter a valid number.');
      return false;
    }

    // selectedOption === 'custom' && 
    if(columnNames === ""){
      toast.error("Please mention your preferred column names or upload a CSV file with sample data.")
      return false;
    }

    var splitColNames = formatColumnNames(columnNames).split(', ');
    if(splitColNames.some(column => column.trim() === '')){
      toast.error("Column Names cannot be empty. Please check your column names for blank spaces or extra commas.");
      return false;
    }

    if(splitColNames.length > 5) {
      toast.error("We allow only 5 columns for free trials. Please subscribe to Pro to generate datasets with more columns.");
      return false;
    }

    return true;
  };

  const convertJsonStringToArray = (jsonString) => {
    // Use a regular expression to match JSON-like objects, including those with newlines
    const jsonRegex = /{(?:[^{}]|(?:\{[^{}]*\}))*}/g;
    const jsonObjects = jsonString.match(jsonRegex);

    if(jsonObjects === null) return [];
  
    // Parse each JSON object and create an array
    return jsonObjects.map(json => {
      try {
        // Replace escaped newlines with actual newlines
        const cleanedJson = json.replace(/\\n/g, '');
        return JSON.parse(cleanedJson);
      } catch (error) {
        console.error('Error parsing JSON object:', json);
        console.error('Error details:', error);
        return null; // Return null for objects that couldn't be parsed
      }
    }).filter(obj => obj !== null); // Remove any null entries
  };

  const formatColumnNames = (colNames) => {
    return colNames.split(',').map(col => col.trim()).join(', ');
  };
   
  const fetchRowData = async (isLoadMoreClicked) => {
    if (!validateInputs(false)) {
      return;
    }

    setLoading(true);
    setshowSpinner(true);
    setLoadMoreNumber(0);

    if(!isLoadMoreClicked) {
      setRowData([]);
      setIsDisabled(true);
      setReceivedCount(0);
      setTotalRequiredNumber(Number(number));
    }
    else{
      console.log("Updating Records Number:")
      setLoadMoreNumber(100);
      setTotalRequiredNumber(prevTotal => prevTotal + 100);
    }

    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('usecase', selectedOption);
      if(isLoadMoreClicked){
        formData.append('number', 100);
      }
      else{
        formData.append('number', number);
      }
      formData.append('language', selectedLanguage);
      formData.append('user_id', user.uid);
      formData.append('include_search_results', isChecked);
      formData.append('temperature', temperature);
      formData.append('model', selectedModel);
      console.log("Is Open Source:")
      console.log(isOpenSource);
      formData.append('is_open_source', isOpenSource);

      if (uploadedFile) {
        formData.append('file', uploadedFile);
      }

    // Convert dynamic column names into colDefs
    const generateColDefs = (columnNames) => {
      return columnNames.split(', ').map(column => ({
        field: column, editable: true, resizable: true, sortable: true, wrapText: true, autoHeight: true, flex: 2
      }));
    };

    if(columnNames != "") {
      const formattedColNames = formatColumnNames(columnNames);
      const colDefs = generateColDefs(formattedColNames)
      setColDefs(colDefs);
      formData.append('column_names', formattedColNames);
    }
    else
    {
      if (selectedOption === 'classification') {
        // "degree, major, GPA, subjects, work experience, preferred locations, recommended courses"
          formData.append('column_names', "Text, Label");
          setColDefs([
            {
              field: "Text", editable: true, resizable: true, sortable: true, 
              wrapText: true, 
              autoHeight: true, 
              flex: 4
            },
            { field: "Label", editable: true, flex: 1}
          ]);
        }
      else if (selectedOption === 'generation') {
        setColDefs([{ field: "Text", editable: true, flex: 5 }]);
      }
      else if (selectedOption === 'qanda') {
        formData.append('column_names', "Question, Answer");
        setColDefs([
          {
            field: "Question", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Answer", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 3
          },
        ]);
      }
      else if(selectedOption === 'insttuning'){
        formData.append('column_names', "Instruction, Input, Output");
        setColDefs([
          {
            field: "Instruction", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Input", editable: true, resizable: true, sortable: true, 
            wrapText: true, 
            autoHeight: true, 
            flex: 2
          },
          {
            field: "Output", editable: true, resizable: true, sortable: true, 
            wrapText: true,
            autoHeight: true, 
            flex: 3
          }]
        )
      }
    }
      
      const startTime = performance.now();
      const response = await fetch(`${API_URL}/api/generate-data`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(true);
      // const results = await response.json();
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          setIsDisabled(false);
          setshowSpinner(false);
          setIsPopupOpen(true);
          // if(rowData.length <= 0){
          //   toast.error("Data could not be loaded. Please try again later!");
          // }
          // else{
          toast.info("Data Loading has been completed. You can now download the data as a CSV file.");
          // }
          // const csvContent = prepareCSVContent();
          // saveToServer(rowData, "csv_generated_")
          break
          // console.log("Row Data Length: ");
          // console.log(rowData.length);
          // if(rowData.length === number) {
          //   toast.info("Data Loading has been completed. You can now download the data as a CSV file.");
          // }
          // else {
          //   toast.error("Data Loading Failed. Please try after some time or change your prompt and inputs.");
          // }
        }
        const chunk = decoder.decode(value);
        const items = JSON.parse(JSON.stringify(chunk));
        console.log("Items")
        console.log(items);

          const items_converted = convertJsonStringToArray(items);
          if("message" in items_converted[0]){
            toast.error(items_converted[0].message);
            break
          }
          setShowGrid(true);
          setLoading(false);
          // prevData.length >= Number(number) ? prevData : 
          setRowData(prevData => [...prevData, ...items_converted]);
          // prevCount + items_converted.length >= Number(number) ? number :
          setReceivedCount(prevCount =>  prevCount + items_converted.length);
      }

      const endTime = performance.now();
      console.log('It took ' + (endTime - startTime) + ' ms.');

    } catch (error) {
      console.error('Error fetching row data:', error);
      toast.error("An Error occured! Please try again or contact the support team.")
    }
    finally {
        if(!isLoadMoreClicked){
          setLoading(false);
          setShowGrid(true);
          setIsDisabled(false);
          setshowSpinner(false);
        }
    }
  };

  const handleDropdownChange = (event) => {
    var userSelection = event.target.value
    if(userSelection === "classification"){
      setShowColNames(true);
    }
    else{
      setShowColNames(false);
    }
    setColumnNames("");
    setSelectedOption(userSelection);
    setRowData([]);
    setColDefs([]);
    setShowGrid(false);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  }

  const handleSubmit = () => {
    if (validateInputs()) {
      setShowResults(true);
      fetchRowData(false);
      // setTimeout(() => {
      //   containerRef.current?.scrollTo({
      //     left: containerRef.current.scrollWidth / 2,
      //     behavior: 'smooth',
      //   });
      // }, 100); 
      // setTimeout(() => {
      //   if (containerRef.current) {
      //     containerRef.current.style.transform = "translateX(-100vw)";
      //   }
      // }, 100);  // Delay to ensure state updates

      setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: 'smooth' });  // Scroll to results section
      }, 100);
    }
  };

  const prepareCSVContent = () => {
    const escapeCSV = (cell) => {
      if (cell == null) return '';
      cell = cell.toString();
      if (cell.includes('"') || cell.includes(',') || cell.includes('\n')) {
        return `"${cell.replace(/"/g, '""')}"`;
      }
      return cell;
    };

    const csvHeader = colDefs.map(col => escapeCSV(col.field)).join(',') + '\n';
    const csvData = rowData.map(row => 
      colDefs.map(col => escapeCSV(row[col.field])).join(',')
    ).join('\n');

    const BOM = '\uFEFF';
    const csvContent = BOM + csvHeader + csvData;
    return csvContent;
  };

  const readCSVHeader = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        const firstLine = content.split('\n')[0];
        const headers = firstLine.split(',').map(header => header.trim());
        resolve(headers);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const saveToServer = async (csvData, filename) => {
    try {
      const response = await fetch(`${API_URL}/api/downloaded-csv`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: csvData,
          userId: user.uid,
          filename: filename
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to save file to server');
      }
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error saving to server:', error);
      throw error;
    }
  };

  const downloadCSV = () => {
    const csvContent = prepareCSVContent();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
    setDialogOpen(false);
    // event.preventDefault();
    saveToServer(csvContent, "csv_downloaded_")
    .then(() => {
      console.log('Successfully saved to server');
    })
    .catch((error) => {
      console.error('Failed to save to server:', error);
    });
  };

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDownloadClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSubmitEvaluation = async(evaluation_instructions) => {
    const formData = new FormData();
    const csvContent = prepareCSVContent();
    formData.append('csvData', csvContent);
    formData.append('userId', user.uid);
    formData.append('evalComments', evaluation_instructions);
    formData.append('colNames', columnNames);
    formData.append('userPrompt', prompt);
  
    try {
      toast.success("File Sent for Evaluation. We will get back to you as soon as your dataset is ready.");
      const response = await fetch(`${API_URL}/api/evaluate-csv`, {
        method: 'POST',
        body: formData
      });
      // const decoder = new TextDecoder();
      // const reader = response.body.getReader();
      // const { value } = await reader.read();
      // const chunk = decoder.decode(value);
      // const items = JSON.parse(JSON.stringify(chunk));
    } catch (error) {
      toast.error('Error sending CSV to backend:', error);
    }
  };

  const handleBackToForm = () => {
    // setShowResults(false);
    setTimeout(() => {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });  // Scroll to results section
    }, 100);
    // // Slide back to the form
    // setTimeout(() => {
    //   containerRef.current?.scrollTo({ 
    //     top: 0, 
    //     behavior: 'smooth'
    //   });
    // }, 100);
  };

  // const handleSlide = () => {
  //     setShowResults(true);
  //     setTimeout(() => {
  //       containerRef.current?.scrollTo({ 
  //         left: containerRef.current.scrollWidth, 
  //         behavior: 'smooth'
  //       });
  //     }, 100);
  // };

  const handleLoadMore = () => {
    // setIsLoadMoreClicked(true);
    // setShowResults(true);
    setloadedNumUntilNow(loadedNumUntilNow + loadMoreNumber);
    fetchRowData(true);
  };

  const handleSourceToggle = () => {
    // console.log("Open Source FLag:");
    // setIsOpenSource(!isOpenSource);
    // console.log(isOpenSource);
    if(isOpenSource){
      setIsOpenSource(false);
      setSelectedModel("Gemini-1.5-Flash");
    }
    else{
      setIsOpenSource(true);
      setSelectedModel("Meta-Llama-3.1-405B-Instruct");
    }
  };
  
  return (
    // <div>
    //   <Joyride
    //     steps={steps}
    //     run={run}
    //     continuous={true}
    //     showProgress={true}
    //     showSkipButton={true}
    //     disableBeacon={true}
   // className="flex overflow-x-hidden w-screen"
    //   />
    <div className = "overflow-hidden">
      <Joyride
        steps={steps}
        run={runTour}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: '#e3ffeb',
            backgroundColor: 'white',
            overlayColor: 'rgba(79, 26, 0, 0.4)',
            primaryColor: '#000',
            textColor: '#004a14',
            textAlign: 'left',
            width: 600,
            zIndex: 1000,
          }
        }}
      />
      <div className="flex transition-transform duration-500 ease-in-out" style={{ width: '200vw' }}>
      <div className="flex-shrink-0 w-screen">
        <div className="container mx-auto p-4 max-w-4xl">
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            {/* <button
              onClick={startTour}
              className="float-right mb-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Start Tour
            </button> */}
            {/* <button
                  onClick={handleSlide}
                  className="float-right bg-gray-200 text-gray-700 px-4 py-2 rounded-lg mr-2 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  <ArrowRightIcon className="w-5 h-5" />
            </button> */}
          <div className="mb-6">
          <label id = "promptLabel" htmlFor="prompt" className="block text-sm font-medium text-gray-700 mb-2 p-2">
            Describe the data you want to generate in detail in English*
          </label>
          
          <div className="relative">
            <textarea
              id="prompt"
              required
              rows="5"
              className="w-full p-3 pr-10 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />
            {/* PaperClipIcon positioned inside textarea */}
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <button
                type="button"
                id="fileupload"
                onClick={() => fileInputRef.current.click()}
                className="text-gray-400 hover:text-gray-600"
              >
                <PaperclipIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

        {fileName && (
          <div className="mt-2 text-sm text-green-600 flex items-center">
            <span>File selected: {fileName}</span>
            <button onClick={handleRemoveFile} className="ml-2 text-red-500 hover:text-red-700">
              <XIcon className="w-4 h-4" />
            </button>
          </div>
        )}
        
        <input
          required
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          className="hidden"
          accept=".csv"
        />
      </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              {/* <div>
                <label id="task" htmlFor="task" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Task*
                </label>
                <div className="relative">
                  <select
                    value={selectedOption}
                    onChange={handleDropdownChange}
                    className="w-full p-2 border border-gray-300 rounded-lg appearance-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="custom">Custom</option>
                    <option value="classification">Text Classification</option>
                    <option value="qanda">Question Answering</option>
                    <option value="insttuning">Instruction Tuning</option>
                  </select>
                  <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div> */}

              <div>
                <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Language*
                </label>
                <div className="relative">
                  <select
                    id="language"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    className="w-full p-2 border border-gray-300 rounded-lg appearance-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                    <option value="te">Telugu</option>
                  </select>
                  <ChevronDownIcon className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>

              <div>
                <label htmlFor="modelDropdown" className="block text-sm font-medium text-gray-700 mb-1">
                  Select Model
                </label>
                <select
                  id="modelDropdown"
                  value={selectedModel}
                  onChange={handleModelChange}
                  isDisabled={isModelDisabled}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {models.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>

                </div>

              <div>
                <label htmlFor="number" className="block text-sm font-medium text-gray-700 mb-1">
                  Min. Number of Results*
                </label>
                <input
                  required
                  type="number"
                  id="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="">
            {/* Horizontal Alignment */}
            <div className="flex items-center space-x-6 w-full">
              {/* Temperature */}
              {/* <div className="flex-1">
                <label htmlFor="temperature" className="block text-sm font-medium text-gray-700 mb-1">
                  Temperature* (0-2)
                </label>
                <div className="flex items-center">
                  <input
                    type="range"
                    id="temperature"
                    min="0"
                    max="2"
                    step="0.1"
                    value={temperature}
                    onChange={(e) => setTemperature(parseFloat(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                  <span className="ml-4 text-sm text-gray-700">{temperature.toFixed(1)}</span>
                </div>
              </div> */}

              {/* Toggle */}
              {/* <div className="flex-1">
                <div className="flex items-center space-x-2">
                  <label htmlFor="sourceToggle" className="text-sm font-medium text-gray-700">
                    Open Source Models
                  </label>
                  <div
                    onClick={handleSourceToggle}
                    className={`relative inline-block w-16 h-8 rounded-full transition-all duration-300 ease-in-out ${
                      isOpenSource ? "bg-green-500" : "bg-gray-300"
                    }`}
                  >
                    <div
                      className={`absolute top-1 left-1 w-6 h-6 bg-white rounded-full transition-all duration-300 ease-in-out transform ${
                        isOpenSource ? "translate-x-8" : ""
                      }`}
                    ></div>
                  </div>
                </div>
              </div> */}

              {/* Models Dropdown */}
              {/* <div className="flex-1">
                <label htmlFor="modelDropdown" className="block text-sm font-medium text-gray-700">
                  Select Model
                </label>
                <select
                  id="modelDropdown"
                  value={selectedModel}
                  onChange={handleModelChange}
                  isDisabled={isModelDisabled}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {models.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
          </div>

      <div className="flex items-center justify-between py-2">
      <input
        type="text"
        id="columnNames"
        value={columnNames}
        placeholder='Enter comma separated column names to have custom columns.*'
        onChange={(e) => setColumnNames(e.target.value)}
        className="w-full mr-4 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
      />
      <div className="flex gap-3 flex-shrink-0">
        <button
          id="go"
          onClick={handleSubmit}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Go Now
        </button>
        <button
          id="later"
          onClick={handleOrderForLater}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          {orderLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0116 0"></path>
            </svg>
          ) : 'Order For Later'}
        </button>
      </div>
    </div>
          </div>
      </div>
      <div>
      {showResults && (
          <div ref={containerRef} className="container mx-auto p-4">
            {/* {loading && (
              <div className="flex justify-center items-center h-32">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            )} */}

            {(
              <div className="bg-white rounded-lg shadow-md p-4">
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={handleBackToForm} disabled
                    className="mr-4 text-blue-600 hover:text-blue-800 focus:outline-none"
                  >
                    {/* <ArrowBigUp className="w-6 h-6" /> */}
                  </button>
                  <span className="text-md text-gray-600 flex items-center">
                  {/* + Number(loadMoreNumber) */}
                    {receivedCount} of {totalRequiredNumber} rows loaded
                    {showSpinner && (
                      <div className="ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                    )}
                  </span>
                  <div>
                    {/* <button
                      onClick={handleLoadMore}
                      disabled={isDisabled}
                      className="mr-2 px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                     Load 100 More
                    </button> */}
                    <button
                      onClick={handleButtonClick}
                      disabled={isDisabled}
                      className="mr-2 px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                      Submit For Evaluation
                    </button>
                    <button
                      onClick={handleDownloadClick}
                      disabled={isDisabled}
                      className="px-4 py-2 bg-white text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
                    >
                      Download CSV
                    </button>
                  </div>
                </div>
                <div>
                  {<GridComponent rowData={rowData} colDefs={colDefs} />}
                </div>
                <PopupFormComponent
                  open={isPopupOpen}
                  onClose={handleClosePopup}
                  onSubmit={handleSubmitEvaluation}
                />
                {/* <PromptAssistantModal
                  isOpen={ispromptModalOpen}
                  onClose={() => setIsPromptModalOpen(false)}
                  promptText={promptAssistant}
                  setPromptText={setPromptAssistant}
                  onConfirm={handleConfirm}
                /> */}
                <TermsDialog open={dialogOpen} handleClose={handleDialogClose} handleAgree={downloadCSV} />
              </div>
            )}
          </div>
      )}
      </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
      <PromptAssistantModal 
          open={ispromptModalOpen}
          onClose={handlePromptAssistant}
          promptAssistant={promptAssistant}
          onUpdate={handleUpdate}
      />
      {/* <FeedbackModal 
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="How was your purchase experience?"
        subtitle="Help us improve our checkout process"
      /> */}
      {/* <FeedbackPopup /> */}
      {/* <div className="flex justify-end">
        <button
          onClick={handleSlide}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          <ArrowRightIcon className="w-5 h-5" />
        </button>
      </div> */}
    </div>
   </div>
  );
};

export default Home;