import React, { useState, useEffect } from 'react';
import { Star, X } from 'lucide-react';
import { API_URL } from './constants';

const FeedbackModal = ({
  user,
  title = "Quick Feedback",
  subtitle = "How is your experience with DataCreator AI?"
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleFeedbackSubmit = async() => {
    setSubmitted(true);
    try {
     var feedbackData = {
        user_id: user.uid,
        rating: rating,
        feedback: feedback
     };

    await fetch(`${API_URL}/api/submit-feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(feedbackData)
      });
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  // useEffect(() => {
  //   // Check if feedback has already been shown
  //   const hasShownFeedback = sessionStorage.getItem('feedbackShown');
    
  //   if (hasShownFeedback) {
  //     return; // Exit early if already shown
  //   }

  //   const handleMouseLeave = (e) => {
  //     // Only show if not already shown and mouse moves to top
  //     if (e.clientY <= 0 && !sessionStorage.getItem('feedbackShown')) {
  //       setIsVisible(true);
  //       // Mark as shown immediately
  //       sessionStorage.setItem('feedbackShown', 'true');
  //     }
  //   };

  //   document.addEventListener('mouseleave', handleMouseLeave);

  //   return () => {
  //     document.removeEventListener('mouseleave', handleMouseLeave);
  //   };
  // }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 relative">
        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>

        {!submitted ? (
          <div className="space-y-6">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
              <p className="mt-2 text-gray-600">{subtitle}</p>
            </div>

            <div className="flex justify-center space-x-2">
              {[1, 2, 3, 4, 5].map((star) => (
                <button
                  key={star}
                  onClick={() => setRating(star)}
                  className="focus:outline-none transition-transform hover:scale-110"
                >
                  <Star
                    className={`w-8 h-8 ${
                      rating >= star
                        ? 'fill-yellow-400 text-yellow-400'
                        : 'text-gray-300'
                    }`}
                  />
                </button>
              ))}
            </div>

            <textarea
              placeholder="Any specific feedback? (optional)"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
              rows={4}
            />

            <div className="flex space-x-3">
              <button
                onClick={handleClose}
                className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
              >
                Skip
              </button>
              <button
                onClick={handleFeedbackSubmit}
                disabled={rating === 0}
                className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center py-8">
            <h2 className="text-2xl font-bold text-gray-900">Thank You! 🎉</h2>
            <p className="mt-2 text-gray-600">
              Your feedback helps us improve our service.
            </p>
            {rating === 5 && (
              <p className="mt-4 text-sm text-gray-600">
                Love it? Share with others!
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;