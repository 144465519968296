export const API_URL = "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

export const SIGNIN_BENEFITS = [
  "Specify your dataset requirements, whether text, numeric, or a combination, and generate it instantly.",
  "Upload CSV files to augment datasets with synthetic data.",
  "Integrate your own knowledge sources (PDF, JPG) to ensure domain relevance.",
  "Synthetic Data Generation with open and closed source AI models.",
  "Generate data in multiple Indian and foreign languages.",
  "Achieve balance in AI datasets with synthetic data generation."
  // "Contribute to our community datasets and become a paid reviewer."
  // "Pay-per-review model for data evaluation by experts."
];


// "http://localhost:5000"
// https://synthetic-backend-mmlcpe4eca-el.a.run.app
// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"
// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

//  https://synthetic-backend-mmlcpe4eca-em.a.run.app
// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-em.a.run.app"

// "http://localhost:5000"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app"

// "https://synthetic-backend-mmlcpe4eca-el.a.run.app";