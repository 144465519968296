import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  IconButton, Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PromptAssistantModal = ({ open, onClose, promptAssistant, onUpdate }) => {
  const [textValue, setTextValue] = useState('');
  var evaluation_instructions = "";
  const [radioValue, setRadioValue] = useState('human');

  useEffect(() => {
    setTextValue(promptAssistant);
  }, [promptAssistant]);

  const handleTextChange = (event) => {
    console.log('Text changed:', event.target.value); // Debug log
    setTextValue(event.target.value);
  };

  const handleConfirm = async() => {
    console.log('Confirming with value:', textValue); 
    await onUpdate(textValue);
    await onClose(textValue);
  }

  const handleIgnore = async() => {
    await onClose("");
  }

  // const handleRadioChange = (event) => {
  //   setRadioValue(event.target.value);
  // };

  // const handleSubmit = () => {
  //   // evaluation_instructions = textValue;
  //   // onSubmit(evaluation_instructions);
  //   onClose();
  // };

  return (
    <Dialog open={open} onClose={onClose}
    PaperProps={{
        sx: {
          width: '100vw',
          height: '100vh',
          maxWidth: '90%',
          maxHeight: '90%'
        }
      }}
    >
      <DialogTitle sx = {{color: 'blue', display: 'flex', alignItems: 'center'}}> Hi! I'm your Prompt Assistant!
        <Tooltip title="Here, you can check and update your requirements in much more detail. You can make updates with possible various of your columns. We will use your inputs to make the dataset customized to your needs.">
            <IconButton
            aria-label="help"
            sx={{ ml: 1, color: 'black' }}
            >
            <HelpOutlineIcon />
            </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          sx={{ width: '100%', height: '100%' }}
          InputLabelProps={{
            style: { whiteSpace: 'normal', maxWidth: '100%' },
          }}
          id="evaluation-text"
          label="Please check this detailed prompt, make changes(if necessary) and submit it."
          type="text"
          fullWidth
          multiline
          rows={20}
          variant="outlined"
          value={textValue}
          onChange={handleTextChange}
        />
      </DialogContent>
      <DialogActions>
        <Button sx = {{color: 'blue'}} onClick={handleIgnore}>Ignore</Button>
        <Button sx = {{color: 'blue'}} onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptAssistantModal;
