import { API_URL } from "./constants";

export const callPythonAPI = async (params) => {
    try {
      const response = await fetch(`${API_URL}/api/generate-detailed-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers like authentication if needed
          // 'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return {
        success: true,
        result : data["updatedPrompt"]
      };
    } catch (error) {
      console.error('Error calling Python API:', error);
      return {
        success: false,
        error: error.message,
      };
    }
  };