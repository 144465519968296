import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';
import { SIGNIN_BENEFITS } from './constants'

const PolicyPoints = () => {
  // const points = [
  //   "Build Ethical AI Compliance for Internal Policies",
  //   "Achieve Compliance with External Regulations",
  //   "Great ROI Compared to Developing an In-House Solution",
  //   "Enhance Customer Satisfaction with Reliable AI Experiences",
  //   "Gain Extensive Data for Ethical AI Analysis and Improvement"
  // ];
  const points = SIGNIN_BENEFITS;

  const [visiblePoints, setVisiblePoints] = useState([]);

  useEffect(() => {
    points.forEach((_, index) => {
      setTimeout(() => {
        setVisiblePoints(prev => [...prev, index]);
      }, index * 600); // 600ms delay between each point
    });
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-8 font-sans">
      {points.map((point, index) => (
        <div
          key={index}
          className={`transform transition-all duration-500 ease-out mb-8 ${
            visiblePoints.includes(index)
              ? 'translate-x-0 opacity-100'
              : '-translate-x-full opacity-0'
          }`}
        >
          <div className="flex items-center space-x-4">
            <CheckCircle 
              className="flex-shrink-0 w-6 h-6 text-blue-400" 
              strokeWidth={2}
            />
            <span className="text-xl text-gray-700">{point}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PolicyPoints;