import React from 'react';
import { Rocket, Shield, Clock, Gift } from 'lucide-react';

const SignupPageLeftSide = () => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
      <div className="border-t pt-6 space-y-6">
        <div className="flex items-start">
          <Rocket className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
          <div>
            <h3 className="font-semibold text-gray-800">Get Started in Minutes</h3>
            <p className="text-gray-600 text-sm">Quick setup process with your Google account. No credit card required.</p>
          </div>
        </div>

        <div className="flex items-start">
          <Gift className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
          <div>
            <h3 className="font-semibold text-gray-800">Instant Data Generation</h3>
            <p className="text-gray-600 text-sm">Generate upto 10,000 data points/month 500 at a time. Limited Period Offer!</p>
          </div>
        </div>
        
        {/* <div className="flex items-start">
          <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
          <div>
            <h3 className="font-semibold text-gray-800">Secure Authentication</h3>
            <p className="text-gray-600 text-sm">Enterprise-grade security with Google Sign-In and two-factor authentication.</p>
          </div>
        </div>

        <div className="flex items-start">
          <Clock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
          <div>
            <h3 className="font-semibold text-gray-800">Instant Access</h3>
            <p className="text-gray-600 text-sm">Start generating synthetic data immediately after signing up.</p>
          </div>
        </div> */}
      </div>
      
      {/* <div className="mt-6 pt-6 border-t">
        <p className="text-center text-sm text-gray-500">
          Join thousands of data scientists and developers already using DataCreator AI
        </p>
      </div> */}
    </div>
  );
};

export default SignupPageLeftSide;