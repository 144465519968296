import React, { useState, useEffect } from 'react';
import { Download, ChevronDown, ChevronUp, Tag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import GridComponent from './UpdatableGrid';
import { API_URL } from './constants';
import { toast, ToastContainer } from 'react-toastify';
import { prepareCSVContent } from './Download';
import { saveAs } from 'file-saver';

const datasets = [
  {
    id: 1,
    name: 'E-commerce Product Reviews for Sentiment Analysis',
    size: '2.5 MB',
    rows: 15000,
    lastUpdated: '2024-12-15',
    filename: 'ecommerce_reviews.csv',
    domain: 'E-commerce'
    // tags: ['NLP', 'Sentiment Analysis']
  },
  {
    id: 2,
    name: 'FAQ of an E-Commerce Application',
    size: '4.8 MB',
    rows: 25000,
    lastUpdated: '2024-12-20',
    filename: 'customer_support_conversations.csv',
    domain: 'Customer Support'
    // tags: ['FAQ', 'Support']
  },
  {
    id: 3,
    name: 'Scam Message Classification Dataset in Telugu',
    size: '1.2 MB',
    rows: 5000,
    lastUpdated: '2024-12-25',
    filename: 'scam_classify_telugu_1000.csv',
    domain: 'Security'
    // tags: ['Classification', 'Regional']
  },
  {
    id: 4,
    name: 'Student Percentage Prediction with Regression',
    size: '',
    rows: 5000,
    lastUpdated: '2024-12-26',
    filename: 'student_final_grades.csv',
    domain: 'Education'
    // tags: ['Regression', 'Analytics']
  }
];

const domainColors = {
  'E-commerce': 'bg-blue-100 text-blue-800',
  'Customer Support': 'bg-green-100 text-green-800',
  'Security': 'bg-red-100 text-red-800',
  'Education': 'bg-purple-100 text-purple-800'
};

const DatasetListing = ({ user }) => {
  const [selectedDomain, setSelectedDomain] = useState('');
  const filteredDatasets = selectedDomain 
    ? datasets.filter(d => d.domain === selectedDomain)
    : datasets;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [expandedDataset, setExpandedDataset] = useState(null);
  const [gridData, setGridData] = useState(null);
  const [gridColumns, setGridColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDatasetId, setCurrentDatasetId] = useState(null);
  const [improvement, setImprovement] = useState('');
  const [background, setBackground] = useState('');
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const navigate = useNavigate();

  const handleDatasetClick = async (dataset) => {
    if (user === null) {
      setIsLoggedIn(false);
      navigate("/signin");
      return;
    }
    
    if (expandedDataset === dataset.id) {
      setExpandedDataset(null);
      setGridData(null);
    } else {
      setExpandedDataset(dataset.id);
      await loadDatasetContent(dataset);
    }
  };

  // const loadDatasetContent = async (dataset) => {
  //   try {
  //     const response = await fetch(`${API_URL}/api/datasets`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         filename: dataset.filename,
  //       }),
  //     });
  
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  
  //     const csvText = await response.text();
  
  //     // Wrap Papa.parse in a Promise
  //     return new Promise((resolve, reject) => {
  //       Papa.parse(csvText, {
  //         header: true,
  //         dynamicTyping: true,
  //         complete: (results) => {
  //           if (!results.meta.fields) {
  //             reject(new Error('No fields found in CSV'));
  //             return;
  //           }
  
  //           const columns = results.meta.fields.map((field) => ({
  //             field,
  //             sortable: true,
  //             filter: true,
  //           }));
  
  //           resolve({ columns, data: results.data });
  //         },
  //         error: (error) => {
  //           console.error('Error parsing CSV:', error);
  //           reject(error);
  //         },
  //       });
  //     });
  //   } catch (error) {
  //     console.error('Error loading dataset:', error);
  //     throw error; // Propagate the error to be handled by the caller
  //   }
  // };

  const loadDatasetContent = async (dataset) => {
    try {
      const response = await fetch(`${API_URL}/api/datasets`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filename: dataset.filename
        }),
      });

      const csvText = await response.text();
      
      Papa.parse(csvText, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          const columns = results.meta.fields.map(field => ({
            field,
            sortable: true,
            filter: true
          }));
          
          setGridColumns(columns);
          setGridData(results.data);
          // resolve({ columns, data: results.data });
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    } catch (error) {
      console.error('Error loading dataset:', error);
    }
  };

  const handleDownload = async (e, dataset) => {
    // if (gridData === null || gridColumns.length === 0) {
    //   // Load data and columns if not already loaded
    //   await loadDatasetContent(dataset);
    //   // data = loaded.data;
    //   // columns = loaded.columns;

    //   // Update state
    //   // setGridData(data);
    //   // setGridColumns(columns);
    // }

    // // Ensure data and columns are available
    // if (!columns || !data) {
    //   console.error('Columns or data is missing');
    //   return;
    // }
    // if(gridData != null || gridColumns.length != 0) {
      const csvContent = prepareCSVContent(gridColumns, gridData);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, dataset.filename);
    // }
    // setGridData(null);
    // setGridColumns([]);
  };

  const handleApplyToReview = (datasetId) => {
    setCurrentDatasetId(datasetId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setImprovement('');
    setBackground('');
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await fetch(`${API_URL}/api/review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.uid,
          datasetId: currentDatasetId,
          improvement: improvement,
          background: background,
        })
      });
      toast.success('Thank you for applying to be a reviewer. Please click on Submit Dataset to add a reviewed version of the dataset.');
      handleModalClose();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Error submitting feedback');
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-blue-600">Available Datasets</h1>
        <button
          onClick={() => navigate("/competition")}
          className="bg-white text-blue-500 px-5 py-2 rounded-md shadow hover:from-blue-600 hover:to-blue-700 transition duration-300"
        >
          Submit Dataset
        </button>
      </div>

      {/* Domain filter buttons */}
      <div className="flex gap-2 mb-6 overflow-x-auto pb-2">
        <button
          onClick={() => setSelectedDomain('')}
          className={`px-4 py-2 rounded-full transition-all ${
            selectedDomain === '' 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          All
        </button>
        {Object.keys(domainColors).map(domain => (
          <button
            key={domain}
            onClick={() => setSelectedDomain(domain)}
            className={`px-4 py-2 rounded-full transition-all ${
              selectedDomain === domain
                ? 'bg-blue-500 text-white'
                : domainColors[domain] + ' hover:opacity-80'
            }`}
          >
            {domain}
          </button>
        ))}
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          {filteredDatasets.map((dataset) => (
            <div key={dataset.id} className="border-b border-gray-200">
              <div
                onClick={() => handleDatasetClick(dataset)}
                className="cursor-pointer hover:bg-gray-50 transition-colors"
              >
                <div className="grid grid-cols-12 px-6 py-4 items-center">
                  <div className="col-span-6">
                    <div className="flex items-center mb-2">
                      {expandedDataset === dataset.id ? (
                        <ChevronUp className="h-4 w-4 mr-2 text-gray-500" />
                      ) : (
                        <ChevronDown className="h-4 w-4 mr-2 text-gray-500" />
                      )}
                      <span className="font-medium text-gray-900">{dataset.name}</span>
                    </div>
                    <div className="flex flex-wrap gap-2 ml-6">
                      <span className={`px-3 py-1 rounded-full text-sm ${domainColors[dataset.domain]}`}>
                        <Tag className="w-3 h-3 inline mr-1" />
                        {dataset.domain}
                      </span>
                      {/* {dataset.tags.map(tag => (
                        <span key={tag} className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm">
                          {tag}
                        </span>
                      ))} */}
                    </div>
                  </div>

                  <div className="col-span-3 text-sm text-gray-500">
                    {dataset.lastUpdated && (
                      <span>Updated: {new Date(dataset.lastUpdated).toLocaleDateString()}</span>
                    )}
                  </div>

                  <div className="col-span-1 flex justify-end">
                    {expandedDataset === dataset.id && gridData && (<button
                      onClick={(e) => handleDownload(e, dataset)}
                      className="text-blue-500 hover:text-blue-700 p-2 rounded-full hover:bg-blue-50 transition-colors"
                    >
                      <Download className="h-5 w-5" />
                    </button>
                    )}
                  </div>
                  <div className="col-span-2 flex justify-end">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleApplyToReview(dataset.id);
                      }}
                      className="ml-2 bg-blue-50 text-blue-600 px-4 py-2 rounded-md hover:bg-blue-100 transition-colors"
                    >
                      Apply to Review
                    </button>
                  </div>
                </div>
              </div>
              
              {expandedDataset === dataset.id && gridData && (
                <div className="px-6 py-4 bg-gray-50">
                  <GridComponent
                    rowData={gridData}
                    colDefs={gridColumns}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-8 w-1/2">  {/* Adjusted width and padding */}
          <h2 className="text-xl font-bold mb-6">Apply to Review the Dataset</h2>  {/* Larger title font for clarity */}
          <form onSubmit={handleFeedbackSubmit}>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 flex items-center">
                What would you improve?
                <span className="ml-1 relative group">
                  <svg
                    className="h-4 w-4 text-gray-500 cursor-pointer"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z" />
                  </svg>
                  <div className="absolute left-6 top-0 w-64 bg-gray-700 text-white text-xs rounded-md p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Let us know specific aspects of the data you think could be better.
                  </div>
                </span>
              </label>
              <textarea
                className="mt-2 block w-full border-gray-300 rounded-md shadow-sm"
                rows="4"
                value={improvement}
                onChange={(e) => setImprovement(e.target.value)}
                required
              />
            </div>
            
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 flex items-center">
                Your Background
                <span className="ml-1 relative group">
                  <svg
                    className="h-4 w-4 text-gray-500 cursor-pointer"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v6h2V7zm0 8h-2v2h2v-2z" />
                  </svg>
                  <div className="absolute left-6 top-0 w-64 bg-gray-700 text-white text-xs rounded-md p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Briefly describe your field or background to help us understand your perspective.
                  </div>
                </span>
              </label>
              <textarea
                className="mt-2 block w-full border-gray-300 rounded-md shadow-sm"
                rows="4"
                value={background}
                onChange={(e) => setBackground(e.target.value)}
                required
              />
            </div>
      
            <div className="flex justify-end">
              <button type="button" onClick={handleModalClose} className="mr-3 bg-gray-200 px-5 py-2 rounded-md">
                Cancel
              </button>
              <button type="submit" className="bg-blue-500 text-white px-5 py-2 rounded-md">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      )}
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default DatasetListing;