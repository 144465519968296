import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TermsAndPrivacy = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/signin');
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    fontFamily: 'Arial, sans-serif',
  };

  const contentStyle = {
    margin: '20px',
    textAlign: 'center',
  };

  const headingStyle = {
    marginTop: '20px',
    marginBottom: '10px',
  };

  const paragraphStyle = {
    textAlign: 'left',
    margin: '0 auto 20px auto',
  };

  const ulStyle = {
    listStyleType: 'disc',
    paddingLeft: '20px',
    textAlign: 'left',
    margin: '0 auto 20px auto',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>Terms of Use</h1>
        <p style={paragraphStyle}>Effective Date: 1st July, 2024</p>

        <h2 style={headingStyle}>1. Acceptance of Terms</h2>
        <p style={paragraphStyle}>
          By accessing and using DataCreator AI (the "Service"), you agree to comply with these Terms of Use ("Terms"). If you do not agree, you must discontinue using the Service.
        </p>

        <h2 style={headingStyle}>2. Use of Service</h2>
        <p style={paragraphStyle}>
          The Service is designed for generating synthetic data for machine learning and AI purposes. You agree not to misuse, reverse-engineer, or attempt to bypass the Service's protections. The following should be agreed before proceeding:
        </p>
        <ul style={ulStyle}>
          <li><b>Explicit Content:</b> Generation of explicit, NSFW, vulgar, or offensive content is prohibited. Violation will result in account suspension or termination.</li>
          <li><b>Ethical Use:</b> Use the data only for ethical and legal purposes.</li>
          <li><b>User Responsibility:</b> You are solely responsible for your use of the data.</li>
          <li><b>Liability:</b> We are not liable for any consequences resulting from data use or misuse.</li>
          <li><b>No Endorsement:</b> Providing this data does not endorse any specific use or interpretation.</li>
          <li><b>Serious Applications:</b> For medical, legal, or health-related uses, verify with a qualified professional first.</li>
        </ul>

        <h2 style={headingStyle}>3. External APIs and Third-Party Services</h2>
        <p style={paragraphStyle}>
          We integrate third-party APIs (e.g., Serper API, Claude Haiku, Google Translate API) for enhanced functionalities. Your use of these third-party services is subject to their respective terms and conditions.
        </p>

        <h2 style={headingStyle}>4. Account and Data Security</h2>
        <p style={paragraphStyle}>
          You agree to provide accurate information when creating an account. Google Signup information will be securely stored and handled in compliance with Indian data protection laws (IT Act, 2000, and IT Rules, 2011).
        </p>

        <h2 style={headingStyle}>5. Termination</h2>
        <p style={paragraphStyle}>We reserve the right to suspend or terminate accounts that violate these Terms.</p>

        <h2 style={headingStyle}>6. Governing Law</h2>
        <p style={paragraphStyle}>These Terms are governed by Indian law and subject to the jurisdiction of Indian courts.</p>

        <h2 style={headingStyle}>7. Email Subscription</h2>
        <p style={paragraphStyle}>You agree to recieve emails about important updates and new features. You can unsubscribe any time.</p>

        <h1 style={headingStyle}>Privacy Policy</h1>
        <p style={paragraphStyle}>Effective Date: 1st July, 2024</p>

        <h2 style={headingStyle}>1. Data Collection</h2>
        <p style={paragraphStyle}>We collect the following information:</p>
        <ul style={ulStyle}>
          <li>Google account information (used for signing in)</li>
          <li>Synthetic data generated via external APIs</li>
        </ul>

        <h2 style={headingStyle}>2. Use of Collected Data</h2>
        <p style={paragraphStyle}>Your data is used to operate the Service and improve user experience. No personal data is shared with third parties without consent.</p>

        <h2 style={headingStyle}>3. Data Security</h2>
        <p style={paragraphStyle}>
          We implement reasonable security practices as per the IT Act (2000) and IT Rules (2011) to protect your data. All stored data is encrypted and securely managed.
        </p>

        <h2 style={headingStyle}>4. Third-Party Services</h2>
        <p style={paragraphStyle}>
          Our platform uses external APIs for data generation. Your interactions with these services are governed by their respective privacy policies and terms.
        </p>

        <h2 style={headingStyle}>5. User Rights</h2>
        <p style={paragraphStyle}>You have the right to access, modify, or delete your personal information. Requests can be made via our contact form.</p>

        <h2 style={headingStyle}>6. Data Retention</h2>
        <p style={paragraphStyle}>We retain user data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>

        <h2 style={headingStyle}>7. Changes to this Policy</h2>
        <p style={paragraphStyle}>We reserve the right to modify this Privacy Policy at any time with prior notice.</p>

        <h2 style={headingStyle}>8. Governing Law</h2>
        <p style={paragraphStyle}>This Privacy Policy is governed by the laws of India.</p>

  <h2 style={headingStyle}>9. Cancellation and Refund</h2>
  <h3 style={headingStyle}>Cancellation Policy</h3>
  <p style={paragraphStyle}>
    Users can cancel their data orders within 24 hours of placing the order by writing to our support team at <a href="mailto:team@datacreatorai.com">team@datacreatorai.com</a>. Any cancellations made after 24 hours will be subject to charges if the order exceeds the trial period offer.
  </p>
  <p style={paragraphStyle}>
    If you wish to delete or deactivate your account, please contact our support team via email at <a href="mailto:team@datacreatorai.com">team@datacreatorai.com</a>.
  </p>

  <h3 style={headingStyle}>Refund Policy</h3>
  <p style={paragraphStyle}>
  Users will only be entitled to a full refund if they have already paid and DataCreator AI fails to fulfill the data order as agreed upon at the time of payment. If partial service has been provided (e.g., a portion of the dataset is delivered), the total amount minus the amount for the partial service will be refunded. This refund policy applies regardless of the size or timeline of the dataset. No other refunds are possible.
  </p>

  <h3 style={headingStyle}>Non-Refundable Services</h3>
  <p style={paragraphStyle}>
    Certain services, including but not limited to, data generation or access to premium features, are non-refundable once the service has been rendered.
  </p>

  <h2 style={headingStyle}>10. Shipping and Delivery</h2>
  <h3 style={headingStyle}>Digital Delivery</h3>
  <p style={paragraphStyle}>
    All services provided by DataCreator AI are delivered digitally. Once the synthetic data is generated or an uploaded dataset has been processed, users will be able to download the generated data from their account page or receive it via email, depending on the service.
  </p>

  <h3 style={headingStyle}>Delivery Time</h3>
  <p style={paragraphStyle}>
    The delivery time for generated datasets entirely depends on the size and complexity of the dataset. We strive to provide a timely delivery, but times may vary depending on the workload and request specifics.
  </p>

  <h3 style={headingStyle}>Data Access</h3>
  <p style={paragraphStyle}>
    All data generated or processed is available for download through the platform. Users are responsible for maintaining the security of their data and must download the files promptly after generation.
  </p>

  <h2 style={headingStyle}>11. Contact Us</h2>
  <p style={paragraphStyle}>
    If you have any questions or concerns about our policies, or if you need assistance, please feel free to contact us at:
  </p>
  <p style={paragraphStyle}>
    <strong>Email:</strong> <a href="mailto:team@datacreatorai.com">team@datacreatorai.com</a>
  </p>
  <p style={paragraphStyle}>
    Alternatively, you can also use the chat icon on our landing page to send us a message.
  </p>
          
          <Button variant="outlined" onClick={handleGoBack} sx={{ mt: 4 }}>
            Go Back to Sign In Page
          </Button>
        </div>
      </div>
    );
  };

export default TermsAndPrivacy;
