import { FaClipboardList, FaFileInvoiceDollar, FaCheckCircle } from "react-icons/fa";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa";

const StepsSection = () => {
  return (
    <div className="flex flex-col justify-center items-center px-8 md:px-16 py-12">
      <h2 className="text-3xl font-bold text-center text-blue-600 mb-12">
        Data Creation in 3 Steps
      </h2>

      <div className="flex flex-col md:flex-row justify-between items-center max-w-5xl w-full mx-auto gap-8">
        
        {/* Step 1 */}
        <motion.div 
          initial={{ opacity: 0, x: -50 }} 
          animate={{ opacity: 1, x: 0 }} 
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className="max-w-sm bg-white p-6 rounded-2xl shadow-lg text-center"
        >
          <FaClipboardList className="text-blue-600 text-4xl mx-auto" />
          <h3 className="text-xl font-semibold mt-4 text-blue-700 truncate">Define Your Requirements</h3>
          <p className="text-gray-700 mt-2 text-left">
            Provide your dataset requirements using our AI powered assistant.
          </p>
        </motion.div>

        {/* Arrow */}
        <motion.div 
          initial={{ opacity: 0, x: -20 }} 
          animate={{ opacity: 1, x: 0 }} 
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }} 
          className="hidden md:flex text-blue-600 text-3xl animate-pulse"
        >
          <FaArrowRight />
        </motion.div>

        {/* Step 2 */}
        <motion.div 
          initial={{ opacity: 0, x: -50 }} 
          animate={{ opacity: 1, x: 0 }} 
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
          className="max-w-sm bg-white p-6 rounded-2xl shadow-lg text-center"
        >
          <FaFileInvoiceDollar className="text-blue-600 text-4xl mx-auto" />
          <h3 className="text-xl font-semibold mt-4 text-blue-700 truncate">Preview & Get a Quote</h3>
          <p className="text-gray-700 mt-2 text-left">
            Receive a sample dataset along with a transparent pricing estimate.
          </p>
        </motion.div>

        {/* Arrow */}
        <motion.div 
          initial={{ opacity: 0, x: -20 }} 
          animate={{ opacity: 1, x: 0 }} 
          transition={{ duration: 0.6, ease: "easeOut", delay: 0.6 }} 
          className="hidden md:flex text-blue-600 text-3xl animate-pulse"
        >
          <FaArrowRight />
        </motion.div>

        {/* Step 3 */}
        <motion.div 
          initial={{ opacity: 0, x: -50 }} 
          animate={{ opacity: 1, x: 0 }} 
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
          className="max-w-sm bg-white p-6 rounded-2xl shadow-lg text-center"
        >
          <FaCheckCircle className="text-blue-600 text-4xl mx-auto" />
          <h3 className="text-xl font-semibold mt-4 text-blue-700 truncate">Pay & Receive Your Dataset</h3>
          <p className="text-gray-700 mt-2 text-left">
            Complete your payment and instantly access your high-quality synthetic dataset.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default StepsSection;